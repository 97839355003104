import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import Header from "../Components/Header";
import "../css/terms.css";
import axios from "axios";

const RajasthaniLudo = () => {
  const [websiteSettings, setWebsiteSettings] = useState(null);

  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  // Fetch website settings
  const fetchWebsiteSettings = async () => {
    try {
      const response = await axios.get(`${baseUrl}/settings/data`);
      setWebsiteSettings(response.data);
    } catch (error) {
      console.error("Error fetching website settings:", error);
    }
  };

  useEffect(() => {
    fetchWebsiteSettings();
  }, []);

  return (
    <div className="">
      <div className="leftContainer">
        <Header />
        <div className="content mt-5 py-4 px-3">
          <div className="m-3">
            <h1>Withdrawal Policy</h1>
            <p>
              <strong>Introduction</strong>
              <br />
              Congratulations on winning! Our withdrawal policy ensures timely and secure payouts. Please read this policy carefully.
            </p>
            <p>
              <strong>Eligibility</strong>
              <br />
              To withdraw winnings, players must:
              <ul>
                <li>Have a verified account</li>
                <li>Meet minimum withdrawal requirements (specified below)</li>
                <li>Comply with anti-money laundering (AML) and know-your-customer (KYC) regulations</li>
              </ul>
            </p>
            <p>
              <strong>Withdrawal Requirements</strong>
              <ul>
                <li>Minimum withdrawal amount: ₹100 (or equivalent currency)</li>
                <li>Maximum withdrawal amount: ₹50,000 (or equivalent currency) per transaction</li>
                <li>Withdrawal processing time: 2-5 business days</li>
                <li>Withdrawal methods: Bank Transfer, UPI, Paytm, Net Banking</li>
              </ul>
            </p>
            <p>
              <strong>Withdrawal Procedure</strong>
              <ol>
                <li>Log in to your account and go to the "Withdrawal" section</li>
                <li>Enter the desired withdrawal amount and choose your preferred withdrawal method</li>
                <li>Confirm your withdrawal request</li>
                <li>Our team will verify your request and process the withdrawal</li>
              </ol>
            </p>
            <p>
              <strong>Verification Documents</strong>
              <ul>
                <li>Identity proof (Aadhaar, PAN, Passport)</li>
                <li>Address proof (Utility bill, Bank statement)</li>
                <li>Source of funds documentation (if required)</li>
              </ul>
            </p>
            <p>
              <strong>Withdrawal Fees</strong>
              <ul>
                <li>No fees for withdrawals above ₹1,000</li>
                <li>₹25 fee for withdrawals between ₹100-₹1,000</li>
              </ul>
            </p>
            <p>
              <strong>Withdrawal Cancellation</strong>
              <br />
              Withdrawal requests can be cancelled within 24 hours of submission.
            </p>
            <p>
              <strong>Dispute Resolution</strong>
              <br />
              In case of disputes, our customer support team will assist.
            </p>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default RajasthaniLudo;
